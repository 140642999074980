import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { database, firebaseConfig } from "../../config/firebase";
import { ref, set } from "firebase/database";


export default function Register() {
  const navigate = useNavigate();
  const [customerNum, setcustomerNum] = useState("");
  const [year, setYear] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { currentUser, register, setError } = useAuth();


  const [customerNumError, setcustomerNumError] = useState("");
  const [yearError, setYearError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  useEffect(() => {


    setTimeout(() => {
      if (localStorage.getItem("refreshed")) {
        window.location.href = "/login"; // Forces a full page reload
      }
    }, 12000);
  }, [currentUser, navigate]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setcustomerNumError("");
    setYearError("");
    setPasswordError("");
    setConfirmPasswordError("");



    const currentYear = new Date().getFullYear();
    if (!/^\d{4}$/.test(year) || parseInt(year) < 1900 || parseInt(year) > currentYear) {
      setYearError("Enter a valid year");
      return;
    }

    if (customerNum.length > 11) {
      setcustomerNumError("Incorrect customer number, Please check with your employer.");
      return;
    }

    if (password.length < 6) {
      setPasswordError("Password is too weak.");
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    }

    if (!isStrongPassword(password)) {
      setPasswordError("Password is too weak. Include at least one uppercase letter, one lowercase letter, one number, and one special character.");
      return;
    }

    try {
      setLoading(true);
      const { user } = await register(`${customerNum}${firebaseConfig.emailSuffix}`, password);
      const userData = {
        email: `${customerNum}${firebaseConfig.emailSuffix}`,
        year: year,
        customerNum: customerNum,
      };
      const usersRef = ref(database, `${firebaseConfig.dataPath}${user.uid}`);
      await set(usersRef, userData);

      setTimeout(() => {
        localStorage.setItem("refreshed", true);
        window.location.href = "/login"; // Forces a full page reload
      }, 12000);
    } catch (error) {
      console.error("Error registering:", error);
      setError("Failed to register");
      setTimeout(() => setLoading(false), 12000); // Ensures "Registering..." shows for 8 seconds even on failure
    }
  };

  const isStrongPassword = (password) => {
    const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,}$/;
    return strongPasswordRegex.test(password);
  };

  return (
    <div className="flex items-center justify-center py-6 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <br></br><br></br>
          <h2 className="mt-4 text-3xl text-center tracking-tight font-light text-black">
            Register your account
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleFormSubmit}>
          <div className="mb-4">
            <input
              id="customerNum"
              name="customerNum"
              type="text"
              required
              value={customerNum}
              onChange={(e) => setcustomerNum(e.target.value)}
              className="placeholder-white appearance-none rounded-md block w-full px-4 py-3 placeholder-gray-500 bg-gray-800 border border-gray-600 text-white text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter Customer Number"
            />
            {customerNumError && <p className="text-red-500 text-sm">{customerNumError}</p>}
          </div>


          <div className="mb-4">
            <input
              id="year"
              name="year"
              type="text"
              required
              value={year}
              onChange={(e) => setYear(e.target.value)}
              className="placeholder-white appearance-none rounded-md block w-full px-4 py-3 placeholder-gray-500 bg-gray-800 border border-gray-600 text-white text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              placeholder="Year of birth"
            />
            {yearError && <p className="text-red-500 text-sm">{yearError}</p>}
          </div>

          <div className="mb-4">
            <input
              id="password"
              name="password"
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="placeholder-white appearance-none rounded-md block w-full px-4 py-3 placeholder-gray-500 bg-gray-800 border border-gray-600 text-white text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              placeholder="Password"
            />
            {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
          </div>

          <div className="mb-4">
            <input
              id="confirm-password"
              name="confirm-password"
              type="password"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="placeholder-white appearance-none rounded-md block w-full px-4 py-3 placeholder-gray-500 bg-gray-800 border border-gray-600 text-white text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              placeholder="Confirm Password"
            />
            {confirmPasswordError && <p className="text-red-500 text-sm">{confirmPasswordError}</p>}
          </div>

          <div>
            <button
              type="submit"
              disabled={loading}
              className={`w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                loading ? "bg-gray-500 cursor-not-allowed" : "bg-sky-800 hover:bg-sky-900"
              }`}
            >
              {loading ? "Registering..." : "Register"}
            </button>
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link to="/login" className="text-blue-400 hover:underline">
                Already have an account? Login
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
